import React, { useContext } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import DashbboardHeader from "../../components/dashboard-header/dahboard-header";
import AppButton from "../../components/app-button/app-button.component";
import IconButtonMenu from "../../components/icon-menu/IconButtonMenu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTenant } from "../../data/store/selectors/tenantSelector";
import moment from "moment";
import { GlobalState } from "../../data/Context";

const Tenants = () => {
  const navigate = useNavigate();
  const { numberWithCommas } = useContext(GlobalState);
  const tenants = useSelector(selectTenant).filter(
    (item) => item.userType === "tenant"
  );
  console.log({ tenants });
  return (
    <Sidebar>
      <DashbboardHeader />
      <div className="min-h-screen pt-16 md:pt-28 pb-20 px-8 bg-[#FAF9FF]">
        <div className="bg-white p-4">
          <h1 className="text-2xl font-semibold text-[#353535]">Tenants</h1>
          <p className="text-sm text-[#AEAEAE] max-w-md mt-2">
            Below is a list of all tenants
          </p>
          <div className="flex items-center justify-between mt-4">
            <p className="text-[#AEAEAE] text-lg">
              Total Tenants:{" "}
              <span className="text-black">{tenants?.length}</span>
            </p>
            {/* <AppButton
              buttonType={"outlineMain"}
              text={"Add Tenant"}
              onClick={() => navigate("lease-management",)}
            /> */}
          </div>

          <div className="relative overflow-x-auto mt-8">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="whitespace-nowrap">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Tenant Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Lease Start
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Lease End
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Rent Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Lease Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tenants.map((tenant, idx) => (
                  <tr className="bg-white" key={idx}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {tenant.lastName} {tenant.firstName}
                    </td>
                    <td className="px-6 py-4">
                      {moment(tenant.leaseStartDate).format("d/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4">
                      {moment(tenant.leaseEndDate).format("d/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4">
                      PHP {numberWithCommas(tenant.leasePayment)}
                    </td>
                    <td className="px-6 py-4">
                      <IconButtonMenu contractor={tenant} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Tenants;

import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../components/input/input";
import AppButton from "../../components/app-button/app-button.component";
import ApproveCompletedPage from "../../components/approve-complete-payment-page/approve-completed";
import Footer from "../../components/footer/footer";
import Sidebar from "../../components/sidebar/sidebar";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setService } from "../../data/store/reducers/serviceSlice";
import { selectUser } from "../../data/store/selectors/userSelector";

const ServiceDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stage = location.search.split("=")[1];

  useEffect(() => {
    if (!location.search) {
      navigate(`/post-a-request/service-details?stage=1`);
    }
  }, [location.search, navigate, stage]);

  return (
    <>
      {stage === "1" ? (
        <DetailsStage1 />
      ) : stage === "2" ? (
        <DetailsStage2 />
      ) : (
        // ) : (
        stage === "success" && <Success />
      )}
    </>
  );
};

export default ServiceDetails;

const DetailsStage1 = () => {
  const navigate = useNavigate();
  const form = useLocation().state;

  const [details, setDetails] = useState({
    currency: "",
    amount: "",
    description: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { ...form, ...details };
    console.log({ data });
    navigate("/post-a-request/service-details?stage=2", { state: data });
  };
  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="my-10 md:my-16 mx-auto md:mx-44 w-[80%] md:max-w-md">
        <div>
          <h1 className="font-semibold text-2xl">Service Details & Pricing</h1>
          <p className="text-text text-[15px] my-4 font-medium">
            View detailed information about the selected service provider's
            proposal, including pricing for the project
          </p>
          <div className="flex items-center gap-4 my-8">
            <div className="flex-1">
              <Input
                label={"Select currency"}
                type={"select"}
                placeholder={"select ($)"}
                options={["php"]}
                value={details.currency}
                name={"currency"}
                onChange={handleInput}
              />
            </div>
            <div className="w-[60%] md:w-[70%]">
              <Input
                label={"Amount"}
                placeholder="3000"
                type="text"
                value={details.amount}
                name={"amount"}
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="mb-8">
            <Input
              label={"Describe your service"}
              type={"textArea"}
              placeholder="Give specific details that will describe your needs properly"
              value={details.description}
              name={"description"}
              onChange={handleInput}
            />
            <p className="text-text text-xs my-1 text-right">
              5,000 characters left
            </p>
          </div>
          <AppButton
            buttonType={"pill"}
            text={"continue"}
            type="submit"
            width={"full"}
            onClick={handleSubmit}
          />
        </div>
      </section>
    </Sidebar>
  );
};

const DetailsStage2 = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);

  const form = useLocation().state;
  const [details, setDetails] = useState({
    availableFromDate: "",
    availableToDate: "",
    availableFromTime: "",
    availableToTime: "",
    location: user?.addressLine1,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const data = { ...form, categories: [form.categories], ...details };
    console.log({ data });
    setLoading(true);
    try {
      const res = await axios.post("service/create-service", data);
      console.log({ res });
      toast.success("Service submitted successfully");
      const service = await res.data.service;
      dispatch(setService(service));
      navigate(`/post-a-request/service-details?stage=success`);
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };

  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="my-10 md:my-16 mx-auto md:mx-44 w-[80%] md:max-w-md">
        <h1 className="font-semibold text-2xl">Book Inspection Date</h1>
        <p className="text-text text-[15px] font-medium mt-4 mb-8">
          We will send an agent to come and give inspection of the situation.
          Please let us know when you will be available.
        </p>
        <div className="grid grid-cols-2 gap-4">
          <Input
            type={"datePicker"}
            label={"Available from"}
            value={details.availableFromDate}
            name={"availableFromDate"}
            onChange={handleInput}
          />
          <Input
            type={"datePicker"}
            label={"Available to"}
            value={details.availableToDate}
            name={"availableToDate"}
            onChange={handleInput}
          />
          <Input
            type={"timePicker"}
            label={"From"}
            value={details.availableFromTime}
            name={"availableFromTime"}
            onChange={handleInput}
          />
          <Input
            type={"timePicker"}
            label={"To"}
            value={details.availableToTime}
            name={"availableToTime"}
            onChange={handleInput}
          />
        </div>
        <div className="mt-4">
          <Input
            label={"Location"}
            value={details.location}
            name={"location"}
            onChange={handleInput}
            disabled
          />
        </div>
        <div className="my-8">
          <AppButton
            buttonType={"pill"}
            text={"continue"}
            type="submit"
            width={"full"}
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </section>
    </Sidebar>
  );
};

const Success = () => {
  const pageDetails = {
    header: "Thank you for your request",
    body: "We have received your request. Our team will review your request and get back to you shortly with a quote",
    buttonText: "Check quotes",
    navigateTo: "/pending-requests",
  };

  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="min-h-screen flex flex-col justify-center items-center">
        <ApproveCompletedPage pageDetails={pageDetails} />
      </section>
      <footer>
        <Footer />
      </footer>
    </Sidebar>
  );
};

export { DetailsStage1, DetailsStage2, Success };

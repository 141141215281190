import React from "react";
import NotificationComponent from "../../components/notification/NotificationComponent";

const Notifications = () => {
  return (
    <div>
      <NotificationComponent />
    </div>
  );
};

export default Notifications;

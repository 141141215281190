import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  tenants: [],
  loading: false,
  error: null,
};

const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    setTenant: (state, { payload }) => {
      state.tenants.push(payload);
    },
    updateTenant: (state, { payload }) => {
      state.tenants = updateTenantAccount(payload, state.tenants);
    },
    deleteOneTenant: (state, { payload }) => {
      state.tenants = removeTenant(payload, state.tenants);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTenants.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchTenants.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.tenants = payload;
    });
    builder.addCase(fetchTenants.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.response?.data?.error;
    });
  },
});

export const { setTenant, updateTenant, deleteOneTenant } = tenantSlice.actions;
export default tenantSlice.reducer;

export const fetchTenants = createAsyncThunk(
  "tenant/fetchTenants",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/admin/all-users");
      console.log({ res });
      const tenants = await res.data.users;
      return tenants;
    } catch (error) {
      console.log({ fetchTenantsError: error });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const updateTenantAccount = (tenant, tenants) => {
  // const foundTenant = tenants.find(
  //   (item) => item._id === tenant._id
  // );

  const newTenants = tenants.map((item) => {
    if (item._id === tenant._id) return tenant;
    return item;
  });
  return newTenants;
};

const removeTenant = (tenant, tenants) => {
  const foundTenant = tenants.filter((item) => item._id === tenant._id);
  return foundTenant;
};

const cancelTenant = (tenant, tenants) => {
  const foundTenant = tenants.filter((item) => item._id === tenant._id);
  return foundTenant;
};

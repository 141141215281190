import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppButton from "../../components/app-button/app-button.component";
import Sidebar from "../../components/sidebar/sidebar";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllServices,
  fetchServices,
  removeService,
} from "../../data/store/reducers/serviceSlice";
import { selectRole } from "../../data/store/selectors/userSelector";
import moment from "moment";

const Request = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeService = useLocation().state;
  const [service, setService] = useState();
  const [loading, setLoading] = useState(false);
  const role = useSelector(selectRole);
  const normalTitle = id.replace(/-/g, " ").toUpperCase();
  console.log({ service });

  useEffect(() => {
    if (!activeService) navigate("/pending-services");
    setService(activeService);
  }, []);

  const cancleRequest = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`/service/cancel-service/${service._id}`);
      console.log({ res });
      toast.success("Removed Successfully");
      const removedService = await res.data.service;
      dispatch(removeService(removedService));
      navigate("/pending-requests");
    } catch (error) {
      console.log({ error });
      toast.error(error.response.data.error);
    }
    setLoading(false);
  };

  const verifyPayment = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `/payment/${service?._id}/successful-payment`
      );
      console.log({ res });
      // toast.success("Removed Successfully");
      // const removedService = await res.data.service;
      dispatch(fetchAllServices());
      dispatch(fetchServices());
      navigate("/");
    } catch (error) {
      console.log({ error });
      toast.error(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  const completeRequest = async (serviceId) => {
    setLoading(true);
    try {
      const res = await axios.post(`/service/complete-service/${serviceId}`);
      console.log({ res });
      // toast.success("Removed Successfully");
      // const removedService = await res.data.service;
      dispatch(fetchAllServices());
      dispatch(fetchServices());
      navigate("/");
    } catch (error) {
      console.log({ error });
      toast.error(error.response.data.error);
    }
    setLoading(false);
  };

  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="px-6 md:px-16 py-4 md:py-8">
        <h1 className="font-bold md:text-xl mb-6">{normalTitle}</h1>
        <div className="border border-gray rounded-xl md:max-w-[80%] p-4 md:p-6 space-y-5">
          <h2 className="font-medium text-lg">
            Service Type: {service?.categories[0]}
          </h2>
          <p className="text-sm text-text font-medium">
            {service?.description}
          </p>
        </div>
        <div className="border rounded-xl border-gray my-10 max-w-[100%] xl:max-w-[80%] p-4 md:p-6">
          <div className="border- border-gray">
            <h2 className="font-medium text-lg">Description of work</h2>
            <ul className="list-disc list-inside space-y-2 my-5 px-3 text-sm font-medium text-text">
              <li>{service?.description}</li>
            </ul>
          </div>
        </div>
        <div className="border rounded-xl border-gray my-10 max-w-[100%] xl:max-w-[80%] p-4 md:p-6">
          <div className="border- border-gray">
            <h2 className="font-medium text-lg">Availability</h2>
            <h2 className="font-medium text-sm mt-4">Date</h2>
            <ul className="list-disc list-inside space-y-2 px-3 text-sm font-medium text-text">
              <li>
                From:{" "}
                {moment(service?.availableFromDate).format(
                  "dddd, DD MMMM yyyy"
                )}
              </li>
              <li>
                To:{" "}
                {moment(service?.availableToDate).format("dddd, DD MMMM yyyy")}
              </li>
            </ul>
            <h2 className="font-medium text-sm mt-4">Time</h2>
            <ul className="list-disc list-inside space-y-2 px-3 text-sm font-medium text-text">
              <li>
                From:{" "}
                {moment(service?.availableFromTime, "hh:mm").format("h:mm a")}
              </li>
              <li>
                To: {moment(service?.availableToTime, "hh:mm").format("h:mm a")}
              </li>
            </ul>
          </div>
        </div>

        <div className="border rounded-xl border-gray my-10 max-w-[100%] xl:max-w-[80%] p-4 md:p-6">
          <div className="border-b border-gray">
            <h2 className="text-lg font-medium">Terms</h2>
            <ul className="list-disc list-inside my-5 px-3 space-y-2 text-sm font-medium text-text">
              <li>
                Payment: 50% deposit required upon acceptance, balance due upon
                completion.
              </li>
              <li>Timeline: Estimated completion within 2 weeks.</li>
              <li>
                Warranty: Topspot provides a 2-month warranty on workmanship and
                materials.
              </li>
              <li>
                Changes: Additional charges may apply for scope changes or
                upgrades
              </li>
              <li>Cancellation: 2 days notice required for cancellations.</li>
              <li>
                Liability: TopSpot is not liable for damages except in cases of
                negligence
              </li>
            </ul>
          </div>
          <div className="pt-5">
            <div className="flex items-center gap-3 sm:gap-6">
              {!service?.paid && (
                <AppButton
                  buttonType={"primary"}
                  text={"Begin Service"}
                  onClick={() => verifyPayment()}
                  // onClick={() => navigate(`/pending-requests/approve-quote`)}
                />
              )}
              {service?.paid && (
                <AppButton
                  buttonType={"primary"}
                  text={"Complete Service"}
                  loading={loading}
                  onClick={() => completeRequest(service?._id)}
                  // onClick={() => navigate(`/pending-requests/approve-quote`)}
                />
              )}
              {/* <AppButton
                  buttonType={"outlineBlack"}
                  text={"Send Counter Offer"}
                  onClick={() =>
                    navigate("/counter-offer?stage=1", { state: service })
                  }
                  loading={loading}
                /> */}
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default Request;

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import DashbboardHeader from "../../components/dashboard-header/dahboard-header";
import moment from "moment";
import AppButton from "../../components/app-button/app-button.component";
import axios from "axios";

const Lease = () => {
  const [lease, setLease] = useState();
  const [loading, setLoading] = useState(false);

  const makePayment = async () => {
    if (!lease) return;
    setLoading(true);
    try {
      const res = await axios.post(`/payment/make-payment/${lease._id}`, {
        paymentType: "lease",
      });
      console.log({ res });
      window.location.href = res.data.checkoutUrl;
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchActiveLease = async () => {
      try {
        const res = await axios.get("/lease/active");
        console.log({ res });
        setLease(res.data.data);
      } catch (error) {
        console.log({ error });
      }
    };
    fetchActiveLease();
  }, []);

  return (
    <Sidebar>
      <DashbboardHeader />
      <div className="min-h-screen pt-16 md:pt-28 pb-20 px-8 bg-[#FAF9FF]">
        <div className="bg-white p-4">
          <h1 className="text-2xl font-semibold text-[#353535]">
            Current Lease
          </h1>
          <p className="text-sm text-[#AEAEAE] max-w-md mt-2">
            Below is the current active lease agreement
          </p>
          <div className="flex items-center justify-between mt-4">
            {/* <p className="text-[#AEAEAE] text-lg">
              Total Payments:{" "}
              <span className="text-black">{payments?.length}</span>
            </p> */}
            <AppButton
              buttonType={"primary"}
              text={"Renew Lease"}
              loading={loading}
              onClick={makePayment}
            />
          </div>

          {!lease ? (
            <div className=" flex justify-center py-12">No active lease</div>
          ) : (
            <div className="relative overflow-x-auto mt-8">
              <table className="w-full text-sm text-left rtl:text-right">
                <tr className="bg-white">
                  <th scope="col" className="px-6 py-3">
                    Lease Start
                  </th>
                  <td className="px-6 py-4">
                    {moment(lease.leaseStartDate).format("DD MMM YYYY")}
                  </td>
                </tr>
                <tr className="bg-white">
                  <th scope="col" className="px-6 py-3">
                    Lease End
                  </th>
                  <td className="px-6 py-4">
                    {moment(lease?.leaseEndDate).format("DD MMM YYYY")}
                  </td>
                </tr>
                <tr className="bg-white">
                  <th scope="col" className="px-6 py-3">
                    Lease Amount
                  </th>
                  <td className="px-6 py-4">PHP {lease?.leasePayment}</td>
                </tr>
                <tr className="bg-white">
                  <th scope="col" className="px-6 py-3">
                    Lease Status
                  </th>
                  <td className="px-6 py-4">{lease.status}</td>
                </tr>
                <tr className="bg-white">
                  <th scope="col" className="px-6 py-3">
                    Payment Terms
                  </th>
                  <td className="px-6 py-4">{lease?.paymentTerms}</td>
                </tr>
                {/* <tr className="bg-white">
                  <th scope="col" className="px-6 py-3">
                    Contract File
                  </th>
                  <td className="px-6 py-4">
                    <a
                      href="/path/to/pdf"
                      target="__blank"
                      className="underline"
                    >
                      Download Pdf
                    </a>
                  </td>
                </tr> */}
              </table>
            </div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default Lease;

import { useForm, Controller } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";

import AppButton from "../../components/app-button/app-button.component";
import { ReactComponent as Google } from "../../assets/svg/google.svg";
import Input, { Checkbox } from "../../components/input/input";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GlobalState } from "../../data/Context";
import ChooseRole from "../../components/choose-role/choose-role";
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages, //async functions
} from "react-country-state-city";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stage = location.search?.split("=")[1];
  const { choice } = useContext(GlobalState);
  console.log({ choice });

  // useEffect(() => {
  //   if (!location.search) {
  //     navigate(`/register?stage=1`);
  //   }
  //   console.log({ stage });
  // }, [location.search, navigate, stage]);

  useEffect(() => {
    if ((!choice || choice === "") && stage) {
      navigate(`/register?stage=1`);
    }
  }, []);

  return (
    <div className="bg-[#F4F4F4] flex items-center justify-cente flex-col gap-4 py-20 min-h-screen">
      <h1 className="text-2xl text-center">
        Welcome to TopSpot Property Management
      </h1>
      <div className="bg-white p-6 rounded-lg">
        {stage === "1" || !stage ? (
          <LookingFor />
        ) : stage === "2" ? (
          choice === "tenant" ? (
            <AddTenant />
          ) : (
            <SignUp />
          )
        ) : stage?.split("&")[0] === "verify" ? (
          <Verify />
        ) : stage?.split("&")[0] === "create-password" ? (
          <EnterPassword />
        ) : stage === "3" ? (
          choice === "contractor" ? (
            <MeetYou />
          ) : (
            <AddTenantLocation />
          )
        ) : stage === "4" ? (
          choice === "contractor" ? (
            <ServiceArea />
          ) : (
            <SetPassword />
          )
        ) : choice === "contractor" && stage === "5" ? (
          <Expertise />
        ) : (
          stage === "success" && <Success />
        )}
      </div>
    </div>
  );
};

export default Register;

const SignUp = () => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const validatePassword = (value) => {
    // Use watch to get the value of the password field
    const password = watch("password");
    // Check if the passwords match
    return value === password || "Passwords do not match";
  };

  const onSubmit = async (data) => {
    console.log({ data });
    navigate("/register?stage=3", { state: data });
  };
  return (
    <form className="max-w-md mx-auto" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="col-span-2">
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email format",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Enter you email"}
                placeholder="Email"
                type={"email"}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.email && (
            <p className="text-red text-xs">This field is required.</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="password"
            control={control}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                message:
                  "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Password (6 or more characters)"}
                placeholder="Password"
                type={"password"}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.password && (
            <p className="text-red text-xs">{errors.password.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: "This field is required",
              validate: validatePassword,
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label="Confirm Password"
                type="password"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.confirmPassword && (
            <p className="text-[#dc2626] text-xs">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>
      </div>
      <div className="text-right">
        <Link
          to={"/forgot-password"}
          className="italic text-right text-xs text-blue-500"
        >
          Forgot Password
        </Link>
      </div>
      <div className="mt-4">
        <p className="text-[#5E5D60] text-sm text-center max-w-md mx-auto">
          By creating an account you agree to our Agreement privacy policy and
          Cookie policy
        </p>
      </div>
      <div className="mt-4">
        <AppButton
          text={"Create Account"}
          buttonType={"pill"}
          width={"full"}
          type="submit"
        />
      </div>
      {/* <div className="flex items-center gap-2 my-4">
        <div className="border border-lightGray w-full"></div>
        <p className="whitespace-nowrap text-xs">Or with email</p>
        <div className="border border-lightGray w-full"></div>
      </div>
      <div>
        <button className="h-12 w-full border rounded-md border-gray-400 flex items-center justify-center gap-2">
          <span>
            <Google />
          </span>
          <span className="font-semibold text-sm">Sign in with Google</span>
        </button>
      </div> */}
      <div className="text-center mt-4">
        <p className="text-md text-text">
          New here?{" "}
          <span
            className="font-bold text-main hover:text-yellow-600 cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Sign In
          </span>
        </p>
      </div>
    </form>
  );
};

const SetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { choice } = useContext(GlobalState);
  const form = useLocation().state;
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const validatePassword = (value) => {
    // Use watch to get the value of the password field
    const password = watch("password");
    // Check if the passwords match
    return value === password || "Passwords do not match";
  };

  const onSubmit = async (data) => {
    console.log({ ...form, ...data });

    setLoading(true);
    try {
      const res = await axios.post("/auth/signup", { ...form, ...data, userType: choice });
      console.log({ res });
      // toast.success("Tenant created successfully");
      // const tenant = await res.data.user;
      // dispatch(setTenant(tenant));
      navigate("?stage=success");
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };
  return (
    <form className="max-w-md mx-auto" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4 mt-8">
        <div>
          <Controller
            name="password"
            control={control}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                message:
                  "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label="Password"
                type="password"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.password && (
            <p className="text-[#dc2626] text-xs">{errors.password.message}</p>
          )}
        </div>
        <div>
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: "This field is required",
              validate: validatePassword,
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label="Confirm Password"
                type="password"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.confirmPassword && (
            <p className="text-[#dc2626] text-xs">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <p className="text-[#5E5D60] text-sm text-center max-w-md mx-auto">
          By creating an account you agree to our Agreement privacy policy and
          Cookie policy
        </p>
      </div>
      <div className="mt-4">
        <AppButton
          text={"Create Account"}
          buttonType={"pill"}
          width={"full"}
          type="submit"
          loading={loading}
        />
      </div>
      <div className="text-center mt-4">
        <p className="text-md text-text">
          Already have an account?{" "}
          <span
            className="font-bold text-main hover:text-yellow-600 cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Log In
          </span>
        </p>
      </div>
    </form>
  );
};

const EnterPassword = () => {
  const navigate = useNavigate();
  const location = useLocation().search;
  const [loading, setLoading] = useState(false);

  const id = location.split("&")[1].split("=")[1];
  const token = location.split("&")[2].split("=")[1];
  // console.log({ id, token });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const validatePassword = (value) => {
    // Use watch to get the value of the password field
    const password = watch("password");
    // Check if the passwords match
    return value === password || "Passwords do not match";
  };

  const onSubmit = async (data) => {
    console.log({ data });
    setLoading(true);
    try {
      const res = await axios.post(
        `/auth/forgot-password?id=${id}&token=${token}`,
        data
      );
      console.log({ res });
      toast.success("password created successfully");
      navigate("/login");
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };

  return (
    <form className="max-w-md mx-auto" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid sm:grid-cols-2 gap-4 min-w-72">
        <div className="col-span-2">
          <Controller
            name="password"
            control={control}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                message:
                  "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Password (6 or more characters)"}
                placeholder="Password"
                type={"password"}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.password && (
            <p className="text-red text-xs">{errors.password.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: "This field is required",
              validate: validatePassword,
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label="Confirm Password"
                type="password"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.confirmPassword && (
            <p className="text-[#dc2626] text-xs">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>
      </div>
      <div className="mt-4">
        {/* <p className="text-[#5E5D60] text-sm text-center max-w-md mx-auto">
          By creating an account you agree to our Agreement privacy policy and
          Cookie policy
        </p> */}
      </div>
      <div className="mt-4">
        <AppButton
          text={"Continue"}
          buttonType={"pill"}
          width={"full"}
          type="submit"
          loading={loading}
        />
      </div>
    </form>
  );
};

const Success = () => {
  // const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async () => {
    // dispatch(login("top spot"));
    navigate("/login");
  };

  return (
    <div className=" w-[90%] md:max-w-md mx-auto">
      <article className="mb-4 text-center space-y-6">
        <h1 className="text-2xl font-semibold">Complete</h1>
        <p className="text-text">
          Your account has been created. Follow the button below to access your
          dashboard and post a service you need as soon as possible
        </p>
      </article>
      <div className="mt-6">
        <AppButton
          text={"Proceed"}
          // loading={loading}
          buttonType={"pill"}
          width={"full"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

const ServiceArea = () => {
  const [countryId, setCountryId] = useState(0);
  const [stateid, setStateid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);

  // const [cityid, setCityid] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const form = location.state;
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      country: "",
      state: "",
      servicePostalCode: "",
    },
  });

  console.log({ countriesList });

  const onSubmit = async (data) => {
    console.log({ data });
    navigate(`/register?stage=5`, { state: { ...form, ...data } });
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);
  useEffect(() => {
    GetState(countryId).then((result) => {
      setStateList(result);
    });
  }, [countryId, getValues]);
  return (
    <form
      className="w-[90%] md:max-w-md mx-auto"
      onSubmit={handleSubmit(onSubmit)}
    >
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">Set Your Service Area</h1>
        <p className="text-text font-medium mt-2">
          Specify the areas where you offer your services by setting your
          service area below
        </p>
      </article>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="col-span-2">
          <Controller
            name="country"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Country"}
                type={"select"}
                selectHolder="select country"
                options={
                  countriesList.map((country) => country?.name) || [
                    "Loading...",
                  ]
                }
                value={value}
                onChange={(e) => {
                  onChange(e);
                  const newVal = e.target.value;
                  let selectedValue = countriesList.filter(
                    (country) => country.name === newVal
                  );
                  console.log({ selectedValue });
                  setCountryId(selectedValue[0]?.id);
                }}
              />
            )}
          />
          {errors.country && (
            <p className="text-red text-xs">{errors.country.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="state"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"State/Region"}
                type={"select"}
                selectHolder="select region"
                options={
                  stateList.map((country) => country?.name) || ["Loading..."]
                }
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.state && (
            <p className="text-red text-xs">{errors.state.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="servicePostalCode"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Postal Code"}
                placeholder="HT23K"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.servicePostalCode && (
            <p className="text-red text-xs">{errors.postalCode.message}</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <AppButton
          text={"Continue"}
          loading={loading}
          buttonType={"pill"}
          width={"full"}
        />
      </div>
    </form>
  );
};

const Expertise = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const form = location.state;
  console.log({ form });
  const navigate = useNavigate();
  const [expertise, setExpertise] = useState([]);
  const [experience, setExperience] = useState("");
  const { choice } = useContext(GlobalState);

  const handleSelect = (e, value) => {
    if (e.target.checked) {
      setExpertise([...expertise, value.toLowerCase()]);
    } else {
      const mod = expertise.filter((ex) => ex !== value.toLowerCase());
      setExpertise(mod);
    }
  };

  const onSubmit = async () => {
    const data = {
      categories: expertise,
      yearsOfExperience: experience,
      userType: choice,
      ...form,
    };
    console.log({ data });
    setLoading(true);
    try {
      const res = await axios.post("auth/signup", data);
      console.log({ res });
      toast.success(res.data.message);
      navigate(`/register?stage=success`);
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };

  return (
    <div className="w-[90%] md:max-w-md mx-auto">
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">
          Share Your Expertise & Experience
        </h1>
        <p className="text-text font-medium mt-2">
          Tell us about your expertise and experience in your field, so
          homeowners can learn more about your skills and qualifications.
        </p>
      </article>
      <p className="font-semibold text-lg">Select</p>
      <div className="grid sm:grid-cols-2 md:gap-4 mt-4">
        <Checkbox
          label={"Plumbing"}
          onChange={(e) => handleSelect(e, "Plumbing")}
        />
        <Checkbox
          label={"Electrical Work"}
          onChange={(e) => handleSelect(e, "Electrical Work")}
        />
        <Checkbox
          label={"Painting"}
          onChange={(e) => handleSelect(e, "Painting")}
        />
        <Checkbox
          label={"Room Cleaning"}
          onChange={(e) => handleSelect(e, "Room Cleaning")}
        />
        <Checkbox
          label={"Furniture Assembly"}
          onChange={(e) => handleSelect(e, "Furniture Assembly")}
        />
        <Checkbox label={"Other"} onChange={(e) => handleSelect(e, "Other")} />
      </div>
      <div className="my-4">
        <Input
          placeholder={"years"}
          label={"Years of Experience"}
          onChange={(e) => setExperience(e.target.value)}
          value={experience}
        />
      </div>
      <div className="mt-4">
        <AppButton
          text={"Continue"}
          loading={loading}
          buttonType={"pill"}
          width={"full"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

const Verify = () => {
  const navigate = useNavigate();
  const location = useLocation().search;
  const [loading, setLoading] = useState(true);

  const id = location.split("&")[1].split("=")[1];
  const token = location.split("&")[2].split("=")[1];
  const onSubmit = async () => {
    navigate("/login");
  };

  useEffect(() => {
    const verifyAccount = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          // `/auth/verify-account/${id}/${token}`
          `/auth/verify-account?id=${id}&token=${token}`
        );
        console.log({ res });
        toast.success(res.data.success);
        setLoading(false);
      } catch (error) {
        console.log({ error });
        toast.error("There was a problem, please try again");
        setLoading(false);
        navigate("/login");
      }
    };
    verifyAccount();
  }, []);

  if (loading)
    return (
      <div className="p-12">
        <ClipLoader />
      </div>
    );

  return (
    <div className=" w-[90%] md:max-w-md mx-auto">
      <article className="mb-4 text-center space-y-6">
        <h1 className="text-2xl font-semibold">Complete</h1>
        <p className="text-text">
          Your account has been Verified successfully. Follow the button below
          to login to your dashboard and post a service you need as soon as
          possible
        </p>
      </article>
      <div className="mt-6">
        <AppButton
          text={"Proceed"}
          // loading={loading}
          buttonType={"pill"}
          width={"full"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

const LookingFor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setChoice } = useContext(GlobalState);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const reg = location.state;
  console.log({ reg });
  const [roles, setRoles] = useState([
    {
      id: 1,
      value: "I am a home owner and I need services",
      state: false,
      title: "tenant",
    },
    {
      id: 2,
      value: "I Provide Services for Houses",
      state: false,
      title: "contractor",
    },
  ]);

  const handleRole = (id) => {
    const modRoles = roles.map((role) => {
      if (role.id === id) {
        if (role.state) {
          setChoice("");
        } else {
          setChoice(role.title);
        }
        return { ...role, state: !role.state };
      }
      return { ...role, state: false };
    });
    setRoles(modRoles);
  };

  // const handleChoice = async () => {
  //   if (!choice) return toast.error("Select a role");
  //   let data = { ...reg, userType: choice };
  //   console.log({ data });
  //   setLoading(true);
  //   try {
  //     const res = await axios.post("/auth/signup", data);
  //     console.log({ res });
  //     toast.success(res.data.msg);
  //     const user = await res.data?.user;
  //     dispatch(login(user));
  //     navigate(`/onboarding`);
  //   } catch (error) {
  //     console.log({ error });
  //     toast.error(error.response?.data?.error);
  //   }
  //   setLoading(false);
  // };

  const handleChoice = () => {
    navigate("?stage=2");
  };
  return (
    <div className="p-4 xs:p-0 xs:w-[90%] mx-auto md:w-[30rem]">
      <div className="text-center mb-6 space-y-2">
        <h1 className="text-xl font-semibold">What are you looking for?</h1>
        <p className="text-text font-medium">
          Are you a homeowner looking for property management services or a
          service provider?
        </p>
      </div>
      <div className="sp space-y-4">
        {roles.map((role, idx) => (
          <ChooseRole
            key={idx}
            value={role.value}
            state={role.state}
            toggleState={() => handleRole(role.id)}
          />
        ))}
      </div>
      <div className="mt-4">
        <AppButton
          text={"Continue"}
          loading={loading}
          buttonType={"pill"}
          width={"full"}
          onClick={handleChoice}
        />
      </div>
    </div>
  );
};

const MeetYou = () => {
  const location = useLocation();
  const reg = location.state;
  console.log({ reg });
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  const onSubmit = async (data) => {
    console.log({ data });
    navigate(`/register?stage=4`, { state: { ...reg, ...data } });
  };
  return (
    <form
      className="max-w-md mx-auto px-4 md:px-0"
      onSubmit={handleSubmit(onSubmit)}
    >
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">Let's meet you</h1>
        <p className="text-text font-medium mt-2">
          Enter your full name. This will be displayed publicly on your profile
        </p>
      </article>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="col-span-2">
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"First name"}
                placeholder="John"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.firstName && (
            <p className="text-red text-xs">{errors.firstName.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Last name"}
                placeholder="Doe"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.lastName && (
            <p className="text-red text-xs">{errors.lastName.message}</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <AppButton text={"Continue"} buttonType={"pill"} width={"full"} />
      </div>
    </form>
  );
};

const AddTenant = () => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      lodgeName: "",
      tenantRoomNumber: "",
      email: "",
      contactNumber: "",
      secondaryContactNumber: "",
      leasePayment: "",
      leaseStartDate: "",
      leaseEndDate: "",
      password: "",
    },
  });

  const onSubmit = (data) => {
    console.log({ data });
    navigate("/register?stage=3", { state: data });
  };

  return (
    <form
      className="max-w-md mx-auto px-4 md:px-0 md:w-[400px]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">Create Account (Tenant)</h1>
        {/* <p className="text-text font-medium mt-2">
          Enter tenant first name and last name. Confirm the details of the
          tenant before filling this
        </p> */}
      </article>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="col-span-2">
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"First name"}
                placeholder="John"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.firstName && (
            <p className="text-red text-xs">{errors.firstName.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Last name"}
                placeholder="Doe"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.lastName && (
            <p className="text-red text-xs">{errors.lastName.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="lodgeName"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lodge name"}
                placeholder="Ray Lodge"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.lodgeName && (
            <p className="text-red text-xs">{errors.lodgeName.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="tenantRoomNumber"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Room number"}
                placeholder="2b"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.tenantRoomNumber && (
            <p className="text-red text-xs">
              {errors.tenantRoomNumber.message}
            </p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email format",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Enter you email"}
                placeholder="Email"
                type={"email"}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.email && (
            <p className="text-red text-xs">This field is required.</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="contactNumber"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Phone Number"}
                value={value}
                onChange={onChange}
                type={"number"}
              />
            )}
          />
          {errors.contactNumber && (
            <p className="text-red text-xs">{errors.contactNumber.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="secondaryContactNumber"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Secondary Phone Number"}
                type={"number"}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.secondaryContactNumber && (
            <p className="text-red text-xs">
              {errors.secondaryContactNumber.message}
            </p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="leasePayment"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lease amount"}
                value={value}
                onChange={onChange}
                type={"number"}
              />
            )}
          />
          {errors.leasePayment && (
            <p className="text-red text-xs">{errors.leasePayment.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="leaseStartDate"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lease Start Date"}
                value={value}
                onChange={onChange}
                type={"date"}
              />
            )}
          />
          {errors.leaseStartDate && (
            <p className="text-red text-xs">{errors.leaseStartDate.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="leaseEndDate"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lease End Date"}
                value={value}
                type={"date"}
                onChange={onChange}
              />
            )}
          />
          {errors.leaseEndDate && (
            <p className="text-red text-xs">{errors.leaseEndDate.message}</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <AppButton text={"Continue"} buttonType={"pill"} width={"full"} />
      </div>
    </form>
  );
};

export const AddTenantLocation = () => {
  const location = useLocation();
  const [countryId, setCountryId] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const form = location.state;
  // console.log({ form });

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      country: "",
      state: "",
      addressLine1: "",
      addressLine2: "",
    },
  });

  const onChange = async (data) => {
    console.log({ ...data, ...form });
    navigate("/register?stage=4", { state: { ...data, ...form } });
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);
  useEffect(() => {
    GetState(countryId).then((result) => {
      setStateList(result);
    });
  }, [countryId]);

  return (
    <form
      className="max-w-md mx-auto px-4 md:px-0"
      onSubmit={handleSubmit(onChange)}
    >
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">Set your Location</h1>
        <p className="text-text font-medium mt-2">
          Help us find the best services near you by setting your location
          belows
        </p>
      </article>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="col-span-2">
          <Controller
            name="country"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Country"}
                type={"select"}
                selectHolder="select country"
                options={
                  countriesList.map((country) => country?.name) || [
                    "Loading...",
                  ]
                }
                value={value}
                onChange={(e) => {
                  onChange(e);
                  const newVal = e.target.value;
                  let selectedValue = countriesList.filter(
                    (country) => country.name === newVal
                  );
                  console.log({ selectedValue });
                  setCountryId(selectedValue[0]?.id);
                }}
              />
            )}
          />
          {errors.country && (
            <p className="text-red text-xs">{errors.country.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="state"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"State/Region"}
                type={"select"}
                selectHolder="select region"
                options={
                  stateList.map((country) => country?.name) || ["Loading..."]
                }
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.state && (
            <p className="text-red text-xs">{errors.state.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="addressLine1"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Address Line 1"}
                placeholder="Doe"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.addressLine1 && (
            <p className="text-red text-xs">{errors.addressLine1.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="addressLine2"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Address Line 2"}
                placeholder="Doe"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.addressLine2 && (
            <p className="text-red text-xs">{errors.addressLine2.message}</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <AppButton
          text={"Continue"}
          buttonType={"pill"}
          width={"full"}
          loading={loading}
        />
      </div>
    </form>
  );
};

import { useState } from "react";
import orderImage from "../../assets/img/order-image.jpg";
import { ReactComponent as Box } from "../../assets/svg/box.svg";
import { ReactComponent as Calender } from "../../assets/svg/calendar.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const OrdersCard = ({ service }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex gap-4"
      onClick={() =>
        navigate(`/pending-services/${service._id}`, { state: service })
      }
    >
      <img
        src={orderImage}
        alt=""
        className="rounded-2xl h-[80px] lg:h-[100px]"
      />
      <div className="flex justify-between w-full">
        <div className="flex flex-col">
          <div className="flex-1">
            <p className="font-semibold text-dark">{service?.name}</p>
            <p className="text-text text-[15px]">{service?.description}</p>
          </div>
          <div className="flex items-center gap-2 flex-wrap text-text">
            <span className="flex items-center gap-2 whitespace-nowrap">
              <Box />
              <p>{service?.categories[0]}</p>
            </span>
            &bull;
            <span className="flex items-center gap-2 whitespace-nowrap">
              <Calender />
              <p>{moment(service?.availableFromDate).format("DD-MM-yyyy")}</p>
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex-1">
            {/* <p className="text-dark font-semibold">$36.00</p> */}
          </div>
          <p className="text-main font-medium text-sm">{service?.status}</p>
        </div>
      </div>
    </div>
  );
};

export default OrdersCard;

import { styled } from "@mui/system";
import { Tabs as TabsCover } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";

const AppTap = ({ tabs }) => {
  return (
    <Tabs defaultValue={1}>
      <TabsList>
        {tabs?.map((tab, idx) => (
          <Tab key={idx} value={tab?.id}>
            {tab?.title}
          </Tab>
        ))}
      </TabsList>
      {tabs.map((tab, idx) => (
        <TabPanel key={idx} value={tab.id}>
          {tab.component}
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default AppTap;

const Tabs = styled(TabsCover)`
  padding: 16px 0;
`;

const Tab = styled(BaseTab)`
  position: relative;
  color: #636e72;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  padding: 16px 0;

  &:focus {
    color: #fff;
    background-color: #464646;
  }

  &.${tabClasses.selected} {
    color: #fff;
    background-color: #464646;
    // color: #3658c9;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  () => `
    width: 100%;
    font-size: 0.875rem;
    // padding: 20px 12px;
    border-radius: 12px;
    `
);

const TabsList = styled(BaseTabsList)(
  () => `
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    align-content: space-between;
    overflow-x: auto;
    gap: 16px;
    padding: 10px
    `
);

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import america from "../../assets/img/america.jpg";
import AppButton from "../../components/app-button/app-button.component";
import Footer from "../../components/footer/footer";
import Sidebar from "../../components/sidebar/sidebar";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

const Payments = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   navigate("/pending-requests/payments-approved");
  // };
  const serviceId = searchParams.get("quote");
  console.log({ serviceId });

  if (!serviceId) navigate("/");

  useEffect(() => {
    const handlePayment = async () => {
      setLoading(true);
      try {
        const res = await axios.post(`payment/make-payment/${serviceId}`);
        console.log({ res });
        window.location.href = res.data.checkoutUrl;
      } catch (error) {
        console.log({ error });
        toast.error(error.response.data.error);
      } finally {
        setLoading(false);
      }
    };
    handlePayment();
  }, []);

  if (loading)
    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <ClipLoader />
      </div>
    );

  return (
    // <Sidebar sidebarType={"reversed"}>
    //   <div className="mx-auto w-[86%] md:max-w-3xl mt-8 mb-[100px]">
    //     <h1 className="font-medium text-3xl">Payment details</h1>
    //     <p className="text-text text-xl mt-2">
    //       Enter the payments details to complete purchase.
    //     </p>
    //     <form action="" className="my-10">
    //       <div className="md:flex md:items-center space-y-4 md:space-y-0 mt-4 md:space-x-10">
    //         <label
    //           htmlFor="billing-address"
    //           className="text-left flex-none text-text font-medium text-xl"
    //         >
    //           Billing Address
    //         </label>
    //         <InputWithImage
    //           type={"text"}
    //           placeholder={"2732 Vancouver, California"}
    //           image={america}
    //         />
    //       </div>

    //       <div className="md:flex md:items-center mt-8 space-y-4 md:space-x-14 md:space-y-0">
    //         <label
    //           htmlFor="card-number"
    //           className="text-left flex-none text-text font-medium text-xl"
    //         >
    //           Card number
    //         </label>
    //         <div className="flex items-center gap-x-2 md:gap-x-6">
    //           <div className="flex-1 md:flex-grow-[8] md:basis-[80%]">
    //             <InputWithImage
    //               type={"text"}
    //               placeholder={"6037-9973-9731-7331"}
    //               image={america}
    //               className="w-full"
    //             />
    //           </div>
    //           <div className="flex-1 md:flex-grow-[2] md:basis-[20%] flex items-center gap-x-3">
    //             <input
    //               type="text"
    //               name="card-number"
    //               id="card-number"
    //               placeholder="2312"
    //               className="border border-text w-full py-5 rounded-2xl focus:outline-none text-black text-xl text-medium text-center"
    //             />
    //             <input
    //               type="text"
    //               name="card-number"
    //               id="card-number"
    //               placeholder="1/23"
    //               className="border border-text w-full py-5 rounded-2xl focus:outline-none text-black text-xl text-medium text-center"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <p className="md:w-[70%] text-center border-b border-[#9CA3B9] leading-[0.1em] my-12 mx-auto">
    //         <span className="bg-white text-text px-[14px]">or</span>
    //       </p>
    //       <div className="space-y-7 my-10">
    //         <div className="flex gap-3 md:items-center md:gap-16 md:w-[74%]">
    //           <img src="../public/paypal.jpg" alt="paypal" />
    //           <span className="border border-text w-full px-5 md:px-8 py-3 md:py-5 rounded-2xl text-dark text-xl font-medium cursor-pointer">
    //             Paypal with Paypal
    //           </span>
    //         </div>
    //         <div className="flex gap-3 md:items-center md:gap-[5rem] md:w-[74%]">
    //           <img src="../public/cashapp.jpg" alt="cashapp" />
    //           <span className="border border-text w-full px-5 md:text-start md:px-8 py-3 md:py-5 rounded-2xl text-dark text-xl font-medium cursor-pointer">
    //             Paypal with Cashapp
    //           </span>
    //         </div>
    //       </div>
    //       <AppButton
    //         buttonType={"primary"}
    //         text={"continue"}
    //         type="submit"
    //         onClick={(e) => {
    //           e.preventDefault();
    //           navigate("/pending-requests/payments-approved");
    //         }}
    //       />
    //     </form>
    //   </div>
    //   <Footer />
    // </Sidebar>
    <div></div>
  );
};

export default Payments;

const InputWithImage = ({ type, placeholder, image }) => {
  return (
    <div className="relative w-full">
      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex items-center border-r border-text px-2 md:px-4">
        <img src={image} alt="flag" className="h-6 w-auto" />
      </div>

      <input
        type={type}
        placeholder={placeholder}
        className="border border-text w-full pl-14 md:pl-20 py-5 rounded-2xl focus:outline-none text-black text-xl font-medium"
      />
    </div>
  );
};

export { InputWithImage };

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import { ModalDialog } from "@mui/joy";

const AppModal = ({
  isOpen,
  close,
  children,
  title,
  color,
  size,
  closeIcon,
}) => {
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isOpen}
        onClose={close}
      >
        <ModalDialog
          layout="center"
          // size={size ?? "sm"}
          sx={{
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            color: color || "#000000",
            width: {
              md: size === "lg" ? "440px" : "content-fit",
            },
          }}
        >
          {closeIcon && <ModalClose variant="plain" sx={{ m: 1 }} />}
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            {title}
          </Typography>
          <div>{children}</div>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default AppModal;

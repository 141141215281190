import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import { Controller, useForm } from "react-hook-form";
import Input from "../../components/input/input";
import AppButton from "../../components/app-button/app-button.component";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AppTap from "../../components/tab/AppTab";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment/moment";

const AddTenant = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const tenant = location.state;

  const tabs = [
    {
      id: 1,
      title: "Lease management",
      component: <AddNewTenant tenant={tenant} />,
    },
    {
      id: 2,
      title: "Tenant Details",
      component: <TenantDetails tenant={tenant} />,
    },
  ];

  return (
    <Sidebar sidebarType={"reversed"}>
      <div className="py-8 container mx-auto px-4">
        {searchParams.get("success") ? <Success /> : <AppTap tabs={tabs} />}
      </div>
    </Sidebar>
  );
  // return <div>Lease</div>
};

export default AddTenant;

export const AddNewTenant = ({ tenant }) => {
  const navigate = useNavigate();
  const [lease, setLease] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      leaseStartDate: moment(lease?.leaseStartDate).format("yyyy-MM-DD") || "",
      leaseEndDate: moment(lease?.leaseEndDate).format("yyyy-MM-DD") || "",
      leasePayment: lease?.leasePayment || "",
      paymentTerms: lease?.paymentTerms || "",
    },
  });
  const onSubmit = async (data) => {
    console.log({ data });
    setLoading(true);
    try {
      const res = await axios.post(`/lease/${tenant._id}/create`, data);
      console.log({ res });
      setLease(res.data.data);
      toast.success("lease created successfully");
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
    console.log({ data });
  };

  useEffect(() => {
    if (!tenant) navigate("/lease-management");
  }, [tenant]);

  useEffect(() => {
    setValue(
      "leaseStartDate",
      lease?.leaseStartDate
        ? moment(lease?.leaseStartDate).format("yyyy-MM-DD")
        : ""
    );
    setValue(
      "leaseEndDate",
      lease?.leaseEndDate
        ? moment(lease?.leaseEndDate).format("yyyy-MM-DD")
        : ""
    );
    setValue("leasePayment", lease?.leasePayment);
    setValue("paymentTerms", lease?.paymentTerms);
  }, [lease]);

  useEffect(() => {
    const fetchLeaseAgreement = async () => {
      try {
        const res = await axios.get(`/lease/${tenant._id}/active`);
        console.log({ res });
        setLease(res.data.data);
      } catch (error) {
        console.log({ error });
      }
    };
    fetchLeaseAgreement();
  }, []);

  return (
    <form
      className="max-w-2xl mx-auto px-4 md:px-0"
      onSubmit={handleSubmit(onSubmit)}
    >
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">Tenant Lease</h1>
        {/* <p className="text-text font-medium mt-2">
          Enter tenant first name and last name. Confirm the details of the
          tenant before filling this
        </p> */}
      </article>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="">
          <Controller
            name="leaseStartDate"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lease Start Date"}
                value={value}
                onChange={onChange}
                type={"date"}
              />
            )}
          />
          {errors.leaseStartDate && (
            <p className="text-red text-xs">{errors.leaseStartDate.message}</p>
          )}
        </div>
        <div className="">
          <Controller
            name="leaseEndDate"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lease End Date"}
                value={value}
                type={"date"}
                onChange={onChange}
              />
            )}
          />
          {errors.leaseEndDate && (
            <p className="text-red text-xs">{errors.leaseEndDate.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="leasePayment"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lease amount"}
                value={value}
                onChange={onChange}
                type={"number"}
              />
            )}
          />
          {errors.leasePayment && (
            <p className="text-red text-xs">{errors.leasePayment.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="paymentTerms"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Payment terms"}
                value={value}
                onChange={onChange}
                type={"textArea"}
              />
            )}
          />
          {errors.leasePayment && (
            <p className="text-red text-xs">{errors.paymentTerms.message}</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <AppButton
          text={"Continue"}
          buttonType={"pill"}
          width={"full"}
          loading={loading}
        />
      </div>
    </form>
  );
};

const Success = () => {
  const navigate = useNavigate();

  const onSubmit = async () => {
    navigate("/tenants");
  };

  return (
    <div className="w-[90%] md:max-w-md mx-auto mt-16">
      <article className="mb-4 text-center space-y-6">
        <h1 className="text-2xl font-semibold">Complete</h1>
        <p className="text-text">
          Your account has been created. Follow the button below to access your
          dashboard and post a service you need as soon as possible
        </p>
      </article>
      <div className="mt-6">
        <AppButton
          text={"Proceed"}
          buttonType={"pill"}
          width={"full"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

const TenantDetails = ({ tenant }) => {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 mt-8 gap-4">
      <DetailCard title="First Name" value={tenant?.firstName} />
      <DetailCard title="Last Name" value={tenant?.lastName} />
      <DetailCard title="Lodge Name" value={tenant?.lodgeName} />
      <DetailCard title="Room Number" value={tenant?.tenantRoomNumber} />
      <DetailCard title="Address" value={tenant?.addressLine1} />
      <DetailCard title="Phone Number" value={tenant?.contactNumber} />
      <DetailCard title="Email" value={tenant?.email} />
    </div>
  );
};

const DetailCard = ({ title, value }) => {
  return (
    <div className="bg-white p-4 rounded-xl shadow-lg">
      <p className="text-neutral-600">{title}</p>
      <h3 className="font-semibold text-lg">{value}</h3>
    </div>
  );
};

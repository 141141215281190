import { useLocation } from "react-router-dom";
import ApproveCompletedPage from "../../components/approve-complete-payment-page/approve-completed";
import Footer from "../../components/footer/footer";
import Sidebar from "../../components/sidebar/sidebar";

const ApproveQuote = () => {
  const quoteId = useLocation()?.state?._id;
  const pageDetails = {
    header: "Quote Accepted!",
    body: "Thank you for accepting the quote, a service provider will be sent your way",
    buttonText: "Proceed to Payment",
    navigateTo: `/pending-requests/payments?quote=${quoteId}`,
  };
  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="min-h-screen flex flex-col justify-center items-center">
        <ApproveCompletedPage pageDetails={pageDetails} />
      </section>
      <footer>
        <Footer />
      </footer>
    </Sidebar>
  );
};

export default ApproveQuote;

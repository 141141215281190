import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import DashbboardHeader from "../../components/dashboard-header/dahboard-header";
import { Pagination } from "antd";
import moment from "moment";
import axios from "axios";

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(1);
  console.log({ payments });

  useEffect(() => {
    const getPayments = async () => {
      try {
        const res = await axios.get(`/payment?limit=${10}&page=${page}`);
        console.log({ res });
        setPayments(res.data.data);
      } catch (error) {
        console.log({ error });
      }
    };
    getPayments();
  }, [page]);
  return (
    <Sidebar>
      <DashbboardHeader />
      <div className="min-h-screen pt-16 md:pt-28 pb-20 px-8 bg-[#FAF9FF]">
        <div className="bg-white p-4">
          <h1 className="text-2xl font-semibold text-[#353535]">
            Current Payments
          </h1>
          <p className="text-sm text-[#AEAEAE] max-w-md mt-2">
            Below is a list of all payments currently in the system. You can
            view details, edit information, or remove payments as needed
          </p>
          <div className="flex items-center justify-between mt-4">
            <p className="text-[#AEAEAE] text-lg">
              Total Payments:{" "}
              <span className="text-black">{payments?.length}</span>
            </p>
            {/* <AppButton
              buttonType={"outlineMain"}
              text={"Add Payment"}
              onClick={() => navigate("lease-management",)}
            /> */}
          </div>

          <div className="relative overflow-x-auto mt-8">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="whitespace-nowrap">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    S/N
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Due Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap">0d Mar 2025</td>
                  <td className="px-6 py-4">PHP 2,000</td>
                  <td className="px-6 py-4 text-red">Overdue</td>
                  <td className="px-6 py-4">
                    <button className="bg-red h-10 px-2 rounded-sm text-white w-full">
                      Pay now
                    </button>
                  </td>
                </tr> */}
                {payments?.docs?.map((payment, idx) => (
                  <tr className="bg-white">
                    <td className="px-6 py-4 whitespace-nowrap">{idx + 1}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {moment(payment?.createdAt).format("DD MMM YYYY")}
                    </td>
                    <td className="px-6 py-4">PHP {payment?.amount}</td>
                    <td
                      className={`px-6 py-4 ${
                        payment.status === "paid" ? "text-main" : "text-red"
                      }`}
                    >
                      {payment.status}
                    </td>
                    <td className="px-6 py-4">
                      <button className="bg-main h-10 px-2 rounded-sm text-white w-full">
                        View Receipt
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            simple={{ readOnly: true }}
            defaultCurrent={page}
            total={payments.totalItems}
            hideOnSinglePage={true}
            onChange={setPage}
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default Payments;

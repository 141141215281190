import { ReactComponent as Plumbing } from "../../assets/svg/plumbing.svg";
import AppButton from "../app-button/app-button.component";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRole } from "../../data/store/selectors/userSelector";
import {
  CleaningIcon,
  ElectricalIcon,
  FurnitureIcon,
  PaintingIcon,
  PlumbingIcon,
} from "../../utils/icons";
import { ClipLoader } from "react-spinners";

const Quotes = ({ quotes, loading, route }) => {
  const role = useSelector(selectRole);
  console.log({ quotes });
  return (
    <div className="border border-lightGray p-6 lg:p-10 rounded-xl">
      <h1 className="text-[22px] text-main font-bold">
        {role === "tenant" ? "Your quotes" : "Pending Requests"}
      </h1>
      <p className="text-text text-[19px] mt-2">
        Review, approve, manage your quotes here. We're here to make managing
        your property needs seamless. Reach out if you need assistance
      </p>
      <div className="my-5">
        {loading && (
          <div className="text-center py-16">
            <ClipLoader />
          </div>
        )}
        {quotes
          ?.filter((quote) => quote.approve === "pending")
          ?.map((quote) => (
            <QuoteCard key={quotes?._id} quote={quote} route={route} />
          ))}
      </div>
    </div>
  );
};

export default Quotes;

export const Services = ({ services, loading, route }) => {
  const role = useSelector(selectRole);
  console.log({ services });
  return (
    <div className="border border-lightGray p-6 lg:p-10 rounded-xl">
      <h1 className="text-[22px] text-main font-bold">
        {role === "tenant" ? "Your quotes" : "Pending Requests"}
      </h1>
      <p className="text-text text-[19px] mt-2">
        Review, approve, manage your quotes here. We're here to make managing
        your property needs seamless. Reach out if you need assistance
      </p>
      <div className="my-5">
        {loading && (
          <div className="text-center py-16">
            <ClipLoader />
          </div>
        )}
        {services
          ?.filter((service) => service.status === "pending")
          ?.map((service) => (
            <ServiceCard key={service?._id} service={service} route={route} />
          ))}
      </div>
    </div>
  );
};

const QuoteCard = ({ quote, route }) => {
  // let title =
  //   "Electrical fittings for a 3-bedroom apartment in Vancouver California, USA";
  const url = quote?.service?.name.replace(/ /g, "-").toLowerCase();
  const navigate = useNavigate();
  return (
    <>
      <div className="flex space-y-4 md:gap-x-16 justify-between md:items-center flex-col md:flex-row border-b border-lightGray py-6 px-3">
        <div className="flex flex-col md:flex-row md:items-center gap-5">
          {quote?.service?.category === "electrical work" ? (
            <ElectricalIcon />
          ) : quote?.service?.categories[0] === "plumbing" ? (
            <PlumbingIcon />
          ) : quote?.service?.categories[0] === "painting" ? (
            <PaintingIcon />
          ) : quote?.service?.categories[0] === "furniture assembly" ? (
            <FurnitureIcon />
          ) : quote?.service?.categories[0] === "room cleaning" ? (
            <CleaningIcon />
          ) : (
            <Plumbing />
          )}
          <div>
            <p className="text-main font-semibold">
              {quote?.service?.categories[0] || "Electrical work"}
            </p>
            <p className="text-text text-sm mt-1 font-medium">
              {quote?.service?.name ||
                "Electrical fittings for a 3-bedroom apartment in Vancouver California, USA"}
            </p>
          </div>
        </div>
        <AppButton
          buttonType={"pill"}
          text={"View Quote"}
          onClick={() => navigate(`${route}/${url}`, { state: quote })}
        />
      </div>
    </>
  );
};

const ServiceCard = ({ service, route = "/pending-requests" }) => {
  // let title =
  //   "Electrical fittings for a 3-bedroom apartment in Vancouver California, USA";
  const url = service?.name.replace(/ /g, "-").toLowerCase();
  const role = useSelector(selectRole);
  const navigate = useNavigate();
  return (
    <>
      <div className="flex space-y-4 md:gap-x-16 justify-between md:items-center flex-col md:flex-row border-b border-lightGray py-6 px-3">
        <div className="flex flex-col md:flex-row md:items-center gap-5">
          {service?.category === "electrical work" ? (
            <ElectricalIcon />
          ) : service?.categories[0] === "plumbing" ? (
            <PlumbingIcon />
          ) : service?.categories[0] === "painting" ? (
            <PaintingIcon />
          ) : service?.categories[0] === "furniture assembly" ? (
            <FurnitureIcon />
          ) : service?.categories[0] === "room cleaning" ? (
            <CleaningIcon />
          ) : (
            <Plumbing />
          )}
          <div>
            <p className="text-main font-semibold">
              {service?.categories[0] || "Electrical work"}
            </p>
            <p className="text-text text-sm mt-1 font-medium">
              {service?.name ||
                "Electrical fittings for a 3-bedroom apartment in Vancouver California, USA"}
            </p>
          </div>
        </div>
        <AppButton
          buttonType={"pill"}
          text={"View Service"}
          onClick={() => navigate(`${route}/${url}`, { state: service })}
        />
      </div>
    </>
  );
};

export { QuoteCard, ServiceCard };

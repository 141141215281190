import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/sidebar";

const Notification = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get("d") ? searchParams.get("d") : "create";

  const [formData, setFormData] = useState({
    title: "",
    body: "",
  });
  return (
    <Sidebar sidebarType={"reversed"}>
      <div className="container mx-auto px-2">
        <h2 className="capitalize text-2xl font-semibold">{mode} notification</h2>

        <form>
            
        </form>
      </div>
    </Sidebar>
  );
};

export default Notification;

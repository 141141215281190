import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import AppButton from "../../components/app-button/app-button.component";
import { Radio } from "@mui/joy";
import Input from "../../components/input/input";
import { Controller, useForm } from "react-hook-form";
import AppModal from "../../components/modal/appModal";
import { useSelector } from "react-redux";
import { selectUser } from "../../data/store/selectors/userSelector";
import { GoDotFill } from "react-icons/go";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";

const NotificationComponent = () => {
  const [mode, setMode] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector(selectUser);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState();
  console.log({ user });

  const toggleModal = (mode) => {
    if (mode) setMode(mode);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const markNotificationAsRead = async () => {
      try {
        const res = await axios.patch(
          `/notification/${selectedNotification._id}`
        );
        console.log({ res });
        const newNot = notifications.map((not) => {
          if (not._id === selectedNotification._id) {
            return res.data.data;
          } else {
            return not;
          }
        });
        setNotifications(newNot);
      } catch (error) {
        console.log({ error });
      }
    };
    markNotificationAsRead();
  }, [selectedNotification]);
  return (
    <Sidebar sidebarType={"reversed"}>
      <div className="container mx-auto px-2">
        <div className="flex justify-between my-12">
          <h2 className="text-xl font-semibold">All Notifications</h2>
          <div>
            {user?.userType === "admin" && (
              <AppButton
                text={"Create Notification"}
                buttonType={"primary"}
                width={"full"}
                type="submit"
                onClick={() => toggleModal("in-app")}
              />
            )}
          </div>
        </div>
        <NotificationTable
          toggleModal={toggleModal}
          setSelectedNotification={setSelectedNotification}
          notifications={notifications}
          setNotifications={setNotifications}
        />
      </div>

      {/* modal */}
      <AppModal
        isOpen={isOpen}
        close={toggleModal}
        closeIcon={mode === "sms" && true}
      >
        {(mode === "view" || mode === "") && (
          <div className="min-h-72 relative text-sm w-80 pb-10">
            <p className="font-medium">10:00 am</p>
            <div className="mt-4">
              <p>{selectedNotification?.message}</p>
            </div>
            <div className="absolute bottom-0 inset-x-0">
              <p className="text-primary-800">
                <span className="font-semibold">Sent by:</span>
                Admin
              </p>
            </div>
          </div>
        )}
        {mode === "in-app" && (
          <div className="md:w-96 max-w-2xl">
            <div>
              <p className="font-bold text-lg mb-4">Send Notification</p>
            </div>
            <SendInAppNotification
              setNotifications={setNotifications}
              notifications={notifications}
              toggleModal={toggleModal}
            />
          </div>
        )}
      </AppModal>
    </Sidebar>
  );
};

export default NotificationComponent;

const NotificationTable = ({
  toggleModal,
  setSelectedNotification,
  notifications,
  setNotifications,
}) => {
  const user = useSelector(selectUser);

  useEffect(() => {
    const fetchNotification = async () => {
      let res;
      try {
        if (user.userType === "admin") {
          res = await axios.get("/notification/all");
        } else {
          res = await axios.get("/notification");
        }
        console.log({ res });
        setNotifications(res.data.data);
      } catch (error) {
        console.log({ error });
      }
    };
    fetchNotification();
  }, []);
  return (
    <div className="relative overflow-x-auto scrollbar-hide">
      <table className="w-full text-sm text-left rtl:text-right text-neutral-600">
        <thead className="bg-primary-100 whitespace-nowrap">
          <tr className="font-light">
            <th scope="col" className="px-6 py-3 font-light">
              S/N
            </th>
            <th scope="col" className="px-6 py-3 font-light">
              Date
            </th>
            <th scope="col" className="px-6 py-3 font-light">
              Message Title
            </th>
            <th scope="col" className="px-6 py-3 font-light">
              Recipient(s)
            </th>
            <th scope="col" className="px-6 py-3 font-light">
              Message body
            </th>
            <th scope="col" className="px-6 py-3 font-light">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="text-xs">
          {notifications?.map((notification, idx) => (
            <tr className="bg-white border-b whitespace-nowrap">
              <td className="px-6 py-4">{idx + 1}</td>
              <td className="px-6 py-4">
                {moment(notification?.createdAt).format("DD MMMM, YYYY")}
              </td>
              <td className="px-6 py-4">{notification?.title}</td>
              <td className="px-6 py-4">{notification?.recipientType}</td>
              <td className="px-6 py-4">
                <div className="flex items-center gap-2">
                  <span>
                    <GoDotFill
                      color={
                        notification?.recipients.find(
                          (item) => item.recipientId === user._id
                        )?.status === "read"
                          ? "gray"
                          : "green"
                      }
                    />
                  </span>
                  <div className="w-40 whitespace-normal text-xs">
                    {notification?.message}
                  </div>
                </div>
              </td>
              <td className="">
                <button
                  className="px-6 py-4 text-primary-500"
                  onClick={() => {
                    setSelectedNotification(notification);
                    toggleModal("view");
                  }}
                >
                  View Notification
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const SendInAppNotification = ({
  setNotifications,
  notifications,
  toggleModal,
}) => {
  const [checked, setChecked] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      message: "",
    },
  });

  const onSubmit = async (data) => {
    const compiledData = { ...data, recipientType: checked };
    console.log({ compiledData });
    if (!checked) return toast.error("select recipients");
    setLoading(true);
    try {
      const res = await axios.post("/notification", compiledData);
      console.log({ res });
      setNotifications([...notifications, res.data.data]);
      toggleModal();
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };
  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Controller
          name="title"
          control={control}
          rules={{
            required: "This field is required",
          }}
          render={({ field: { value, onChange } }) => (
            <Input
              label="Title"
              placeholder="Title"
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors.title && (
          <p className="text-[#dc2626] text-xs">{errors.title.message}</p>
        )}
      </div>
      <div>
        <Controller
          name="message"
          control={control}
          rules={{
            required: "This field is required",
          }}
          render={({ field: { value, onChange } }) => (
            <Input
              label="Message"
              placeholder="message"
              type={"textArea"}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors.message && (
          <p className="text-[#dc2626] text-xs">{errors.message.message}</p>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <p className="font-semibold">Recipients</p>
        <Radio
          label="Everyone"
          name="recipients"
          value={"everyone"}
          checked={checked === "everyone"}
          size="sm"
          onChange={(e) => setChecked(e.target.value)}
        />
        <Radio
          label="Tenants/Home owner"
          name="recipients"
          value={"tenant"}
          checked={checked === "tenant"}
          onChange={(e) => setChecked(e.target.value)}
          size="sm"
        />
        <Radio
          label="Contractors"
          name="recipients"
          value={"contractor"}
          checked={checked === "contractor"}
          size="sm"
          onChange={(e) => setChecked(e.target.value)}
        />
      </div>
      <div>
        <AppButton
          text={"Send"}
          buttonType={"primary"}
          width={"full"}
          type="submit"
          loading={loading}
        />
      </div>
    </form>
  );
};

import React from "react";
import Sidebar from "../../components/sidebar/sidebar";
import DashbboardHeader from "../../components/dashboard-header/dahboard-header";
import moment from "moment";

const PaymentManagement = () => {
  const payments = [];
  return (
    <Sidebar>
      <DashbboardHeader />
      <div className="min-h-screen pt-16 md:pt-28 pb-20 px-8 bg-[#FAF9FF]">
        <div className="bg-white p-4">
          <h1 className="text-2xl font-semibold text-[#353535]">
            Current Payments
          </h1>
          <p className="text-sm text-[#AEAEAE] max-w-md mt-2">
            Below is a list of all payments currently in the system. You can
            view details, edit information, or remove payments as needed
          </p>
          <div className="flex items-center justify-between mt-4">
            <p className="text-[#AEAEAE] text-lg">
              Total Payments:{" "}
              <span className="text-black">{payments?.length}</span>
            </p>
            {/* <AppButton
            buttonType={"outlineMain"}
            text={"Add Payment"}
            onClick={() => navigate("lease-management",)}
          /> */}
          </div>

          <div className="relative overflow-x-auto mt-8">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="whitespace-nowrap">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Tenant
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Due Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap">
                    Ola-Akande Ayokunle
                  </td>
                  <td className="px-6 py-4">N2,000</td>
                  <td className="px-6 py-4">
                    {moment("25/04/2025").format("d MMM YYYY")}
                  </td>
                  <td className="px-6 py-4 text-red">Overdue</td>
                  <td className="px-6 py-4">
                    <button className="bg-red h-10 px-2 rounded-sm text-white w-full">
                      Send Reminder
                    </button>
                  </td>
                </tr>
                <tr className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap">
                    Ola-Akande Ayokunle
                  </td>
                  <td className="px-6 py-4">N2,000</td>
                  <td className="px-6 py-4">
                    {moment("25/04/2025").format("d MMM YYYY")}
                  </td>
                  <td className="px-6 py-4 text-main">paid</td>
                  <td className="px-6 py-4">
                    <button className="bg-main h-10 px-2 rounded-sm text-white w-full">
                      View Receipt
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default PaymentManagement;
